<template>
  <div id="addSoftware" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container class="base">
      <v-row no-gutters class="titulo ml-10 mt-5">ALTA DE SUCURSAL</v-row>
      <v-container fluid class="addEnterprise">
        <v-row no-gutters justify="end">
          <v-col
            cols="12"
            lg="5"
            md="5"
            sm="5"
            class="marginCol formularios"
            align-self="center"
          >
            <v-row>
              <div class="form-group mb-2">
                <label for="empresa">Empresa</label>
                <select name="empresa" id="empresa" v-model="enterprise">
                  <option disabled selected value="0">Selecciona</option>
                  <option
                    v-for="enterprise in enterprises"
                    :key="enterprise.id"
                    v-bind:value="enterprise.id"
                  >
                    {{ enterprise.razonSocial }}
                  </option>
                </select>
              </div>
            </v-row>
            <!--NOMBRE-->
            <v-row class="mb-2">
              <div class="form-group">
                <label for="name">Nombre</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="name"
                  required
                  maxlength="200"
                  @keypress="alfaNumerico($event)"
                />
              </div>
            </v-row>
            <!--PAIS-->
            <v-row class="mb-2">
              <div class="form-group">
                <label for="country">País</label>
                <input
                  type="text"
                  name="country"
                  id="country"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="country"
                  required
                  maxlength="35"
                  @keypress="alfaNumerico($event)"
                  readonly
                />
              </div>
            </v-row>
            <!--CODIGO POSTAL CP-->
            <v-row class="mb-2">
              <div class="form-group">
                <label for="cp">C.P.</label>
                <input
                  type="text"
                  name="cp"
                  id="cp"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="cp"
                  required
                  maxlength="5"
                  @keypress="isNumber($event)"
                  @change="consultaDireccion()"
                />
              </div>
            </v-row>
            <!--ESTADO-->
            <v-row class="mb-2">
              <div class="form-group">
                <label for="state">Estado</label>
                <input
                  type="text"
                  name="state"
                  id="state"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="state"
                  required
                  maxlength="35"
                  @keypress="alfaNumerico($event)"
                  readonly
                />
              </div>
            </v-row>
            <!--MUNICIPIO-->
            <v-row class="mb-2">
              <div class="form-group">
                <label for="municipality">Municipio</label>
                <input
                  type="text"
                  name="municipality"
                  id="municipality"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="municipality"
                  required
                  maxlength="35"
                  @keypress="alfaNumerico($event)"
                  readonly
                />
              </div>
            </v-row>
            <!--COLONIA-->
            <v-row class="mb-2">
              <div class="form-group">
                <label for="suburb">Colonia</label>

                <select name="suburb" id="suburb" v-model="suburb">
                  <option disabled value="0">Selecciona Colonia</option>
                  <option
                    v-for="suburb in suburbs"
                    :key="suburb.id"
                    v-bind:value="suburb.id"
                  >
                    {{ suburb.name }}
                  </option>
                </select>
              </div>
            </v-row>
            <!--CALLE-->
            <v-row class="mb-2">
              <div class="form-group">
                <label for="street">Calle</label>
                <input
                  type="text"
                  name="street"
                  id="street"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="street"
                  required
                  maxlength="35"
                  @keypress="alfaNumerico($event)"
                />
              </div>
            </v-row>
            <!--NUMERO EXTERIOR-->
            <v-row class="mb-2">
              <div class="form-group">
                <label for="exterior">N° Exterior</label>
                <input
                  type="text"
                  name="exterior"
                  id="exterior"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="exterior"
                  required
                  maxlength="20"
                  @keypress="alfaNumerico($event)"
                />
              </div>
            </v-row>
            <!--NUMERO INTERIOR-->
            <v-row class="mb-2">
              <div class="form-group">
                <label for="interior">N° Interior</label>
                <input
                  type="text"
                  name="interior"
                  id="interior"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="interior"
                  required
                  maxlength="20"
                  @keypress="alfaNumerico($event)"
                />
              </div>
            </v-row>

            <!--TELEFONO-->
            <v-row class="mb-2">
              <div class="form-group">
                <label for="phone">Telefono</label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="Obligatorio"
                  class="inputs"
                  autocomplete="false"
                  v-model="phone"
                  required
                  maxlength="10"
                  @keypress="alfaNumerico($event)"
                />
              </div>
            </v-row>
            <!--CHECKBOX-->
            <v-row class="mb-2">
              <div class="form-group">
                <label for="activa">Sucursal activa</label>
                <v-checkbox
                  name="activa"
                  id="activa"
                  autocomplete="false"
                  v-model="activa"
                  size="20"
                ></v-checkbox>
              </div>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="6" align-self="center">
            <v-row justify="center">
              <button class="botonAmarillo" @click="cancel()">Cancelar</button>
              <button class="botonAmarilloDerecho" @click="validacion()">
                Guardar
              </button>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Sucursal</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-sm-center text-lg-center">
            {{ respuesta }}
          </h3>
          <br />
          <ul style>
            <li v-for="error in errors" :key="error.name">{{ error }}</li>
          </ul>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Validación de datos-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-center text-lg-center text-sm-center">
            ¿Esta seguro de almacenar la sucursal?
          </h3>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="error" text @click="aux()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Sucursal</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="aux()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      name: "",
      phone: "",
      reason: "",
      errors: [],
      respuesta: "",
      confirmation: false,
      advice: false,
      error: false,
      country: "México",
      state: "",
      cp: "",
      exterior: "",
      interior: "",
      municipality: "",
      suburb: 0,
      street: "",
      address: [],
      suburbs: [],
      enterprises: [],
      enterprise: localStorage.empresaIdGlobal,
      activa: true,
    };
  },
  methods: {
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if ((charCode > 31 && charCode < 48) || charCode > 57) {
        evt.preventDefault();
      } else return true;
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    consultaDireccion() {
      if (this.cp.length == 5) {
        this.suburbs = [];
        this.show = true;
        axios
          .get(Server + "/direcciones/" + this.cp, {
            headers: {
              Authorization: localStorage.token,
            },
          })
          .then((response) => {
            console.log(response);
            this.address = response.data;
            this.state = this.address[0].estado;
            this.municipality = this.address[0].municipio;
            this.address.forEach((value, index) => {
              this.suburbs.push({
                id: value.asentamientoId,
                name: value.colonia,
              });
            });
            //console.log(suburbs);
            this.show = false;
          })
          .catch((e) => {
            console.log(e);
            this.show = false;
            if (e.response.status == 401) {
              this.expiration = true;
            }
          });
      } else {
        console.log("Menor a 5");
      }
    },
    validaImss() {
      var imss = this.imss;
      if (imss.length != 11) {
        this.errors.push("Número de IMSS incorrecto");
        return false;
      } else {
        console.log("Correcto");
        console.log(imss.length);
        return false;
      }
    },
    validaRfc() {
      var strCorrecta;
      strCorrecta = this.rfc;
      if (this.rfc.length == 12) {
        var valid = "^(([A-Z]|[a-z]){3})([0-9]{6})((([A-Z]|[a-z]|[0-9]){3}))";
      } else {
        var valid =
          "^(([A-Z]|[a-z]|s){1})(([A-Z]|[a-z]){3})([0-9]{6})((([A-Z]|[a-z]|[0-9]){3}))";
      }
      var validRfc = new RegExp(valid);
      var matchArray = strCorrecta.match(validRfc);
      if (matchArray == null) {
        this.errors.push("El RFC es incorrecto favor de verificarlo.");
        return false;
      } else {
        console.log("Correcto");
        return true;
      }
    },
    validaCp() {
      console.log(this.cp);
      var cp = this.cp;
      if (cp.length != 5) {
        this.errors.push("Código Postal incorrecto");
        return false;
      } else {
        console.log("Correcto");
        console.log(cp.length);
        return false;
      }
    },
    validacion() {
      this.errors = [];
      this.validaCp();
      if (this.name == "" || this.name == " ") {
        this.errors.push("Se debe especificar el Nombre de la Sucursal.");
      }
      if (this.country == "" || this.country == " ") {
        this.errors.push("Se debe especificar el país.");
      }
      if (this.state == "" || this.state == " ") {
        this.errors.push("Se debe especificar el estado.");
      }
      if (this.municipality == "" || this.municipality == " ") {
        this.errors.push("Se debe especificar el municipio.");
      }
      if (this.suburb == "" || this.suburb == 0) {
        this.errors.push("Se debe especificar la colonia.");
      }
      if (this.street == "" || this.street == " ") {
        this.errors.push("Se debe especificar la calle.");
      }
      if (this.country == "" || this.country == " ") {
        this.errors.push("Se debe especificar el país.");
      }
      if (this.state == "" || this.state == " ") {
        this.errors.push("Se debe especificar el estado.");
      }
      if (this.exterior == "" || this.exterior == " ") {
        this.errors.push("Se debe especificar el número exterior.");
      }
      if (this.interior == "" || this.interior == " ") {
        this.errors.push("Se debe especificar el número interior.");
      }
      if (this.phone == "" || this.phone == " ") {
        this.errors.push("Se debe especificar el número telefónico.");
      }
      if (this.errors.length == 0) {
        return (this.advice = true);
      } else {
        this.respuesta = "Por favor corrige el(los) siguiente(s) error(es):";
        this.error = true;
      }
    },
    cancel() {
      this.name = "";
      this.phone = "";
      this.country = "";
      this.cp = "";
      this.state = "";
      this.municipality = "";
      this.suburb = "";
      this.exterior = "";
      this.interior = "";
      this.street = "";
      this.$router.push("/branchList");
    },
    guardar() {
      this.advice = false;
      /* this.respuesta = "La Empresa fue guardada con éxito";
      this.confirmation = true; */
      this.show = true;
      axios
        .post(
          Server + "/sucursales",
          {
            EmpresaId: this.enterprise,
            NombreSucursal: this.name,
            Telefono: parseInt(this.phone),
            Pais: this.country,
            CodigoPostal: this.cp,
            AsentamientoId: this.suburb,
            NumeroExterior: this.exterior,
            NumeroInterior: this.interior,
            Calle: this.street,
            Activo: this.activa,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.name = "";
          this.phone = "";
          this.country = "";
          this.cp = "";
          this.state = "";
          this.municipality = "";
          this.suburb = "";
          this.exterior = "";
          this.interior = "";
          this.street = "";
          this.respuesta = "La Sucursal fue guardada con éxito.";
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.name = "";
            this.phone = "";
            this.country = "";
            this.cp = "";
            this.state = "";
            this.municipality = "";
            this.suburb = "";
            this.exterior = "";
            this.interior = "";
            this.street = "";
            this.respuesta = "La Sucursal no fue almacenada.";
            this.confirmation = true;
          }
        });
    },
    aux() {
      this.confirmation = false;
      this.name = "";
      this.phone = "";
      this.country = "";
      this.cp = "";
      this.state = "";
      this.municipality = "";
      this.suburb = "";
      this.exterior = "";
      this.interior = "";
      this.street = "";
      this.$router.push("/branchList");
    },
  },
  created() {
    this.listar();
  },
};
</script>